// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-email-email-tsx": () => import("./../src/components/email/Email.tsx" /* webpackChunkName: "component---src-components-email-email-tsx" */),
  "component---src-components-letter-letter-tsx": () => import("./../src/components/letter/Letter.tsx" /* webpackChunkName: "component---src-components-letter-letter-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-letter-success-tsx": () => import("./../src/pages/letterSuccess.tsx" /* webpackChunkName: "component---src-pages-letter-success-tsx" */),
  "component---src-pages-letters-tsx": () => import("./../src/pages/letters.tsx" /* webpackChunkName: "component---src-pages-letters-tsx" */)
}

